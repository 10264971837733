import { MdMoreHoriz, MdOpenInNew } from 'react-icons/md';
import { Menu, MenuItem } from '@material-ui/core';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import DatabaseContext from '../../contexts/DatabaseContext';
import ModalContext from '../../contexts/ModalContext';
import * as styles from './CoverPreview.module.css';

const menuToggleDataTestIdPrefix2 = 'cover-preview-menu-toggle-';

const CoverPreview = ({ cover }) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { emitter, events } = useContext(ModalContext);
  const { duplicateCover, deleteCover } = useContext(DatabaseContext);

  const handleOpen = () => navigate(`/app/clbuilder/${cover.id}`);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDuplicate = () => {
    duplicateCover(cover);
    setAnchorEl(null);
  };

  const handleRename = () => {
    emitter.emit(events.CREATE_COVER_MODAL, cover);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    deleteCover(cover.id);
    toast(t('dashboard.toasts.deleted', { name: cover.name }));
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const previews = useStaticQuery(graphql`
    query {
      stockholm2: file(relativePath: { eq: "templates2/stockholm2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 260)
        }
      }
      newyork2: file(relativePath: { eq: "templates2/newyork2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 260)
        }
      }
    }
  `);

  return (
    <div className={styles.resume}>
      <div className={styles.backdrop}>
        <GatsbyImage
          alt={cover.name}
          image={
            previews[cover.metadata.template].childImageSharp.gatsbyImageData
          }
        />
      </div>
      <div className={styles.page}>
        <MdOpenInNew
          color="#fff"
          size="48"
          className="cursor-pointer"
          onClick={handleOpen}
        />
        <MdMoreHoriz
          data-testid={`${menuToggleDataTestIdPrefix2}${cover.id}`}
          color="#fff"
          size="48"
          className="cursor-pointer"
          aria-haspopup="true"
          onClick={handleMenuClick}
        />
        <Menu
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleDuplicate}>
            {t('dashboard.buttons.duplicate')}
          </MenuItem>
          <MenuItem onClick={handleRename}>
            {t('dashboard.buttons.rename')}
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            <span className="text-red-600 font-medium">
              {t('shared.buttons.delete')}
            </span>
          </MenuItem>
        </Menu>
      </div>
      <div className={styles.meta}>
        <span>{cover.name}</span>
        {cover.updatedAt && (
          <span>
            {t('dashboard.lastUpdated', {
              timestamp: dayjs(cover.updatedAt)
                .locale(i18n.language.substr(0, 2))
                .fromNow(),
            })}
          </span>
        )}
      </div>
    </div>
  );
};

export default CoverPreview;

export { menuToggleDataTestIdPrefix2 };
