import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useContext } from 'react';
import { Tabs, Tab } from '@mui/material';
import { MdAdd } from 'react-icons/md';
import firebase from 'gatsby-plugin-firebase';
import * as styles from './dashboard.module.css';
import Button from '../../components/shared/Button';
import CreateResume from '../../components/dashboard/CreateResume';
import CreateCover from '../../components/dashboard/CreateCover';
import LoadingScreen from '../../components/router/LoadingScreen';
import ResumePreview from '../../components/dashboard/ResumePreview';
import CoverPreview from '../../components/dashboard/CoverPreview';
import TopNavbar from '../../components/dashboard/TopNavbar';
import ModalContext from '../../contexts/ModalContext';

function a11yProps(index) {
  return {
    id: `rb-tab-${index}`,
    'aria-controls': `rb-tabpanel-${index}`,
  };
}

const Dashboard = ({ user }) => {
  const { t } = useTranslation();
  const [resumes, setResumes] = useState([]);
  const [covers, setCovers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { emitter, events } = useContext(ModalContext);
  const handleCreateResumeClick = () =>
    emitter.emit(events.CREATE_RESUME_MODAL);
  const handleCreateCoverClick = () => emitter.emit(events.CREATE_COVER_MODAL);

  useEffect(() => {
    const resumesRef = 'resumes';
    const coversRef = 'covers';
    const socketRef = '/.info/connected';
    firebase
      .database()
      .ref(socketRef)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          setLoading(false);
          firebase.database().ref(socketRef).off();
        }
      });

    firebase
      .database()
      .ref(resumesRef)
      .orderByChild('user')
      .equalTo(user.uid)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const resumesArr = [];
          const data = snapshot.val();
          Object.keys(data).forEach((key) => resumesArr.push(data[key]));
          setResumes(resumesArr);
        }
      });

    firebase
      .database()
      .ref(resumesRef)
      .orderByChild('user')
      .equalTo(user.uid)
      .on('child_removed', (snapshot) => {
        if (snapshot.val()) {
          setResumes(resumes.filter((x) => x.id === snapshot.val().id));
        }
      });

    firebase
      .database()
      .ref(coversRef)
      .orderByChild('user')
      .equalTo(user.uid)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const coversArr = [];
          const data = snapshot.val();
          Object.keys(data).forEach((key) => coversArr.push(data[key]));
          setCovers(coversArr);
        }
      });

    firebase
      .database()
      .ref(coversRef)
      .orderByChild('user')
      .equalTo(user.uid)
      .on('child_removed', (snapshot) => {
        if (snapshot.val()) {
          setCovers(covers.filter((x) => x.id === snapshot.val().id));
        }
      });

    return () => {
      firebase.database().ref(resumesRef).off();
      firebase.database().ref(coversRef).off();
    };
  }, [user]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Helmet>
        <title>
          {t('dashboard.title')} | {t('shared.appName')}
        </title>
        <link
          rel="canonical"
          href="https://app.resumesmadehere.com/app/dashboard"
        />
      </Helmet>

      <TopNavbar />

      <div className="container pt-20 px-12 xl:px-0">
        <h1 className={styles.h1}>Resumes & Cover Letters</h1>
        <div className="tabs pt-5">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Resumes" {...a11yProps(0)} />
            <Tab label="Cover Letters" {...a11yProps(1)} />
          </Tabs>
        </div>
        {value === 0 && (
          <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-8 pt-10">
            <CreateResume />
            <Button
              outline
              icon={MdAdd}
              className={styles.create}
              onClick={handleCreateResumeClick}
            >
              New Resume
            </Button>

            {resumes.map((x) => (
              <ResumePreview key={x.id} resume={x} />
            ))}
          </div>
        )}
        {value === 1 && (
          <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-8 pt-10">
            <CreateCover />
            <Button
              outline
              icon={MdAdd}
              className={styles.create}
              onClick={handleCreateCoverClick}
            >
              New Cover Letter
            </Button>

            {covers.map((x) => (
              <CoverPreview key={x.id} cover={x} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
